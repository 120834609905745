import * as React from 'react';
import {Container, Grid, Toolbar } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';

import {useTranslation} from '../../providers/TranslationProvider';
import {usePermissions} from 'components/hooks/usePermissions';
import useOrganizations from 'api/hooks/useOrganizations';
import DeleteOrganization from './DeleteOrganization';

import {
  ActionBarButton,
  MuiCommonText,
  MuiGrid,
  MuiGridContainer,
  MuiGridHeaderContainer,
  MuiSubTitle,
  MuiTitle,
  toolbarStyle
} from './styles/organizations';
import CardOrg from './Card';
import CardSkeleton from './CardSkeleton';
import FormModal from './FormModal';
import {useLocation, useNavigate} from 'react-router-dom';
import {useAuth} from 'components/providers/AuthProvider';
import {useTheme} from '../../providers/CustomThemeProvider';

const NewOrganization = () => {
  const [open, setOpen] = React.useState(false);
  const {t} = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const {hasPermissionFor} = usePermissions();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const query = new URLSearchParams(location.search);

  React.useEffect(() => {
    const createQuery = query.get('create');
    setOpen(createQuery === 'organization');
  }, [query]);

  const handleClose = () => {
    query.delete('create');
    navigate(`${location.pathname}${query && ''}${query}`, {replace: true});
  };

  // TODO finish this component
  return (
    <>
      {hasPermissionFor('create_org') && (
        <ActionBarButton
          variant='outlined'
          color='secondary'
          startIcon={<AddIcon color='primary'/>}
          onClick={() =>
            navigate(`${location.pathname}?create=organization`, {
              replace: true,
            })
          }
          data-cy='btn_new_org'
        >
          <MuiCommonText>
            {t('new_org')}
          </MuiCommonText>
        </ActionBarButton>
      )}
      {open && <FormModal action='create' open={open} onClose={handleClose}/>}
    </>
  );
};

const ActionBar = () => {
  const navigate = useNavigate();
  const {user} = useAuth();

  const {t} = useTranslation();
  // TODO workspace
  const handleMyWorkSpace = () => navigate('/orgs/workspace/scenarios');
  return (
    <Grid container spacing={2}>
      <Grid item>
        <NewOrganization/>
      </Grid>
      <Grid item>
        {(user.type === 'admin' || user.type === 'super_admin') && (
          <ActionBarButton
            variant='outlined'
            color='secondary'
            onClick={handleMyWorkSpace}
            data-cy='my_work_space'
            startIcon={<PersonIcon color='primary'/>}
          >
            <MuiCommonText>
              {t('my_workspace')}
            </MuiCommonText>
          </ActionBarButton>
        )}
      </Grid>
    </Grid>
  );
};

const UpdateOrganization = () => {
  const [open, setOpen] = React.useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const query = new URLSearchParams(location.search);

  React.useEffect(() => {
    const createQuery = query.get('edit');
    setOpen(createQuery === null ? false : true);
  }, [query]);

  const handleClose = () => {
    query.delete('edit');
    setOpen(false);
    navigate(`${location.pathname}${query && ''}${query}`, {replace: true});
  };
  return (
    <>
      {open && (
        <FormModal
          action='update'
          open={open}
          onClose={handleClose}
          organization={location.state}
        />
      )}
    </>
  );
};

const Organizations = () => {
  const {t} = useTranslation();
  const {data, isLoading} = useOrganizations();
  const {user} = useAuth();
  const {setColors, theme} = useTheme();
  React.useEffect(() => {
    setColors('#00BBD4', '#005F64');
  }, []);

  const cardSkeletons = Array.from(Array(Math.round(1116 / 250)).keys());

  return (
    <Container sx={{maxWidth: 'xl'}}>
      <Toolbar sx={toolbarStyle}>
        <MuiGridHeaderContainer container justifyContent='space-between'>
          <Grid item id='title-container'>
            <MuiTitle variant='h7' fontFamily='Raleway'>{t('orgs')}</MuiTitle>
            <MuiSubTitle variant='subtitle1' fontFamily='Raleway'>
              {t('orgs_subtitle')}
            </MuiSubTitle>
          </Grid>
          <Grid item id='action-bar-container'>
            <ActionBar/>
          </Grid>
        </MuiGridHeaderContainer>
      </Toolbar>
      <DeleteOrganization/>
      <UpdateOrganization/>
      <MuiGridContainer container id='cards-container'>
        {isLoading ? (
          <>
            {cardSkeletons.map((c) => (
              <CardSkeleton key={c}/>
            ))}
          </>
        ) : (
          <>
            {data &&
              data.map((organization) => {
                if (user.type === 'admin')
                  return (
                    (user.admin_rights.includes(organization._id) || user.org_id === organization._id) && (
                      <MuiGrid item key={organization._id} data-cy='card_org'>
                        <CardOrg organization={organization} action={'Update'}/>
                      </MuiGrid>
                    )
                  );
                else if (user.orgs_user_level?.length !== 0 || user.type === 'super_admin')
                  return (
                    (user.orgs_user_level?.includes(organization._id) || user.org_id === organization._id || user.type === 'super_admin') && (
                      <MuiGrid item key={organization._id} data-cy='card_org'>
                        <CardOrg organization={organization} action={'Update'}/>
                      </MuiGrid>
                    )
                  );
                else
                  return (
                    <MuiGrid item key={organization._id} data-cy='card_org'>
                      <CardOrg organization={organization} action={'Update'}/>
                    </MuiGrid>
                  );
              })}
          </>
        )}
      </MuiGridContainer>
    </Container>
  );
};
export default Organizations;
