import * as React from 'react';
import UploadIcon from '@mui/icons-material/Upload';

import { useTranslation } from '../../providers/TranslationProvider';
import {
  MuiButton, MuiCard,
  MuiCardActions, MuiGridCommon,
  MuiImg,
} from './styles/uploadImage';

const UploadImage = ({ handleChange, image, name }) => {
  const { t } = useTranslation();
  return (
    <MuiGridCommon container>
      <MuiCard elevation={0}>
        <MuiCardActions>
          <MuiImg
            src={image.type ? URL.createObjectURL(image) : image}
            alt='img'
          />
        </MuiCardActions>
      </MuiCard>
      <MuiGridCommon container >
        <input
          name={name}
          accept='image/*'
          id={`contained-button-file-logo-${name}`}
          style={{display: 'none'}}
          type='file'
          onChange={handleChange}
        />
        <label htmlFor={`contained-button-file-logo-${name}`}>
          <MuiButton
            variant='outlined'
            component='span'
            startIcon={<UploadIcon sx={{color:'#005F64'}} size={18}/>}>
            {t('upload')}
          </MuiButton>
        </label>
      </MuiGridCommon>
    </MuiGridCommon>
  );
};

export default UploadImage;
